<template>
  <v-container
    fluid
    class="ma-0 pa-0"
    style="height:100%;"
    :style="{
      backgroundImage: 'url(/imgs/bg0.jpg)',
      backgroundSize: '100% 100%',
    }"
  >
    >
    <v-row
      class="ma-0 pa-0"
      justify="center"
      style="padding-top: 14vh !important;"
    >
      <v-col
         class="ma-0 pa-0"
         align="center">
        <div
          style="position:relative;background: rgba(76, 175, 80, 0);color:white;"
        >
          <div>
            <v-card
              flat
              style="padding: 18px 32px 22px 32px; background-color: #FFFFFF ;min-width:320px;width:420px;max-width:96vw;border-radius:8px;"
            >
              <v-expand-transition>
                <v-card-subtitle
                  class="ma-0 pa-4"
                  v-show="showErrorInForm"
                  transition="fade-transition"
                  style="font-size:16px;"
                >
                  <div class="d-block error white--text text-center">
                    {{ errForm.message }}
                  </div>
                </v-card-subtitle>
              </v-expand-transition>
              <v-card-text class="pa-0 ma-0">
                <div
                  v-if="1 == 1"
                  class="mb-5"
                  style="width:100%;color:#264B77;font-size:26px;font-weight:600;text-align:center;"
                >
                  Đăng nhập
                </div>
                <v-form
                  class="pa-0 ma-0"
                  id="loginUserForm"
                  ref="loginUserForm"
                  v-model="formValid"
                >
                  <v-text-field
                    outlined
                    class="loginTextFieldClass my-2 mt-4"
                    v-model="email"
                    label="Email *"
                    :rules="[rules.required, rules.emailRules]"
                    prepend-inner-icon="mdi-account"
                    style="font-size: 18px;font-weight:600;color:#444444;"
                    hide-details
                    hint=""
                    @focus="showKeyboard"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    class="loginTextFieldClass mt-4 mb-4"
                    v-model="password"
                    label="Mật khẩu *"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :rules="[rules.required, rules.minPassword]"
                    style="font-size: 20px;font-weight:600;color:#444444;"
                    hide-details
                    hint=""
                    @focus="showKeyboard"
                    v-on:keyup.enter="loginUser"
                  ></v-text-field>
                  
                  <!-- <table class="mb-6" style="width:100%;">
                    <tr>
                      <td>
                        <v-checkbox
                          v-model="rememberMe"
                          hide-details
                          single-line
                          class="mb-0"
                          color="#DD0011"
                          label="Ghi nhớ mật khẩu"
                        ></v-checkbox>
                      </td>
                      <td>
                        
                      </td>
                    </tr>
                  </table> -->
                </v-form>
              </v-card-text>
              <v-card-actions class="pa-0 ma-0 mt-2">
                <v-btn text class='px-0 text-none'
                  color='#686868'
                  @click='callRequestForgetPass'>
                  <b>Quên mật khẩu</b>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingSigninBtn"
                  color="white"
                  text
                  @click="loginUser"
                  class="text-none px-6"
                  style="height: 48px;font-size: 18px;background-color: #264B77;font-weight: 600;"
                  >Đăng nhập</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
          <!-- <div
            style="padding: 1vw;position: absolute;bottom: 4%;width: 100%;left:0%;"
          >
            <v-btn
              :loading="loadingSigninBtn"
              color="white"
              class="green darken-2"
              text
              block
              @click="loginUser"
              style="height: 3vw;font-size: 1.4vw;text-transform: capitalize;"
              >Sign in</v-btn
            >
          </div> -->
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbarApp"
      :timeout="5000"
      :color="colorSnackBar"
      :top="'top'"
      style="top: 8px;"
    >
      <div style="width: 100%; text-align:center;font-size: 16px;">
        {{ messageSnackbarApp }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
// const moment = require("moment");
  import { mapGetters } from "vuex";

export default {
  props: ["login"],
  data() {
    return {
      loadingSigninBtn: false,
      snackbarApp: false,
      colorSnackBar: "info",
      messageSnackbarApp: "",
      email: "",
      password: "",
      showPassword: false,
      rememberMe: false,
      show: false,
      formValid: true,
      errForm: {
        status: false,
        message: "",
      },
      rules: {
        required: (value) => !!value || "(*)",
        emailRules: (v) => v.length >= 3 || "Min 3 characters",
        minPassword: (v) => v.length >= 6 || "Min 6 characters",
      },
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.resetValidate();
      }
    },
  },
  computed: {
    ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
      }),
    showErrorInForm() {
      return this.errForm.status;
    },
  },
  methods: {
    loginUser() {
      if (!this.isValidate()) return;
      else {
        this.callRequestLogin();
      }
    },
    showKeyboard (e) {
      this.$eventBus.$emit("requestShowKeyboard", e.target, 'normal')
    },
    callRequestLogin() {
      this.loadingSigninBtn = true;
      let self = this;
      let reqData = {
        email: this.email,
        pwd: this.password,
        customer_id: this.$root.apiUserCustomerId,
      }
      // console.log(reqData)
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      self.axios
        .post(this.$root.apiUser + "/user/login", reqData, config)
        .then((response) => {
          // console.log(response.data)
          if (response.data.status == "OK") {
            let user = response.data.content
            user.token = response.headers["x-token"]
            this.showMessageApp("Đăng nhập thành công!", "success")
            this.$store
              .dispatch("saveUserInfo", {
                fullname: user.fullname,
                userId: user._id,
                avatar: user.avatar,
                token: user.token,
                role: user.role,
                rememberMe: self.rememberMe,
              })
              .then(() => {
                setTimeout(() => {
                  // this.$router.push({ path: "/" })
                  window.location = '/'
                }, 1000);
              });
          } else if (
            response.data.status == "ERROR_USER_NOT_FOUND" ||
            response.data.status == "ERROR_INFO_NOT_MATCH"
          ) {
            this.showMessageApp(
              "Lỗi! Email hoặc mật khẩu không đúng.",
              "error"
            );
          } else {
            this.showMessageApp("Lỗi! " + response.data.message, "error");
          }

          this.loadingSigninBtn = false;
        })
        .catch((err) => {
          this.loadingSigninBtn = false;
          if( err.response ){
              // console.log(err.response.data); // => the response payload 
              let message = err.response.data.message
              if(err.response.data.status=='ERR_PWD_NOT_MATCH') {
                message = "Email hoặc mật khẩu không trùng khớp!"
              }
              this.showMessageApp(`Lỗi! ${message}` , "error");
              return
          }
          this.showMessageApp("Lỗi! " + err.message, "error");
        });
    },
    async callRequestForgetPass() {
      if (this.email.length<=0) {
        this.showMessageApp(`Lỗi! Vui lòng nhập địa chỉ email và bấm "Quên mật khẩu" để lấy mật khẩu mới.` , "error");
        return
      }
      try {
        await this.axios.post(
          `${this.$root.apiUser}/user/forgetPass`,
          {
            email: this.email,
            customer_id: this.$root.apiUserCustomerId,
          }
        )
        this.showMessageApp(`Mật khẩu mới đã được gửi tới email ${this.email}` , "info")
      } catch (err) { console.log(err) }
    },
    resetValidate() {
      this.$refs.loginUserForm.reset();
    },
    isValidate() {
      let result = true;
      if (!this.$refs.loginUserForm.validate()) {
        this.errForm.status = true;
        setTimeout(() => {
          this.errForm.status = false;
        }, 5000);
        this.errForm.message = "Vui lòng nhập thông tin!";
        result = false;
      }
      return result;
    },
    showMessageApp(message, type) {
      this.snackbarApp = true;
      this.messageSnackbarApp = message;
      this.colorSnackBar = !type ? "info" : type;
    },
  }
};
</script>

<style>
#loginUserForm div label {
}
#loginUserForm div.loginTextFieldClass {
  min-height: 60px;
  line-height: 60px;
}
#loginUserForm div.v-messages__message {
  font-size: 14px;
  min-height: 40px;
  line-height: 40px;
}
</style>
